<template>

<div class="textarea" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />
	
	<textarea class="textarea-input" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="input.label" />

</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error']

}

</script>

<style scoped>

.textarea {
	margin-bottom: 20px;
}

.textarea-input {
	background-color: #f5f5f5;
	padding: 14px 20px;
	height: 126px;
	line-height: 20px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	border-radius: 23px;
	resize: none;
	width: 100%;
}

.textarea-input::placeholder {
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
}

</style>
